export const AUTHENTICATION_FAILED = "authentication failed";
export const AUTH_FAILED = "authentication failed!";
export const SUCCESS = "success";
export const DONE = "done";
export const ACCEPT = "accept";
export const FAILED = "failed";
export const REJECT = "reject";
export const ERROR = "error";
export const NOT_FOUND = "not-found";
export const UNDEFINED = "undefined";
export const VERIFIED = "verified";
export const REUPLOAD = "reupload";
export const INVALID = "Invalid";
export const PAY_ERROR = "Payment Error";
export const UNEXPECTED_ERROR = "An unexpected error occurred";
export const INTERNAL_SERVER_ERROR =
    "Thank you for visiting our website. The system is currently undergoing maintenance. Please try again later.";
export const INVALID_REQUEST_ERROR = "Invalid request";
export const INVALID_LOGIN = "Invalid login";
export const LOGIN_FAILED = "Login failed";
export const VALID_EMAIL_MESSAGE = "Please enter a valid email";
export const EMAIL_EXISTANCE_MESSAGE = "This email address is already in use";
export const WHATSAPP_EXISTANCE_MESSAGE =
    "Account with provided number already exists, please use a different WhatsApp number.";
export const PHONE_NUMBER_EXISTANCE_MESSAGE =
    "This phone number is already in use.";
export const PHONE_NUMBER_NOT_EXISTANCE_MESSAGE =
    "Phone number doesn't exists.";
export const PASSWORD_RESET_INVALID_MESSAGE =
    "Your password reset link is invalid or expired";
export const FAILED_PHONE_NUMBER_CHECK = "Failed to check phone number.";
export const FAILED_SEND_OTP = "Failed to send OTP.";
export const UNVERIFIED_OTP = "Unverified OTP.";
export const INVALID_REGISTRATION_PROCESS = "Invalid registration process.";
export const INVALID_REQUEST = "Invalid request!";
export const VERIFIED_CAPTCHA_TOKEN = "Verified captcha token.";
export const UNVERIFIED_CAPTCHA_TOKEN = "Unverified captcha token.";
export const INVALID_CAPTCHA_TOKEN = "Invalid captcha token.";
export const LEGAL_AGE_CONFIRMATION = "Legal age consent needs to confirm.";
export const INVALID_EMAIL_FORMAT = "Invalid email format.";
export const REQUIRED_PASSWORD_LENGTH =
    "Password needs to be 8 to 30 characters long.";
export const REQUIRED_PASSWORD_DIGIT = "Password needs to have 1 digit.";
export const INVALID_CITY = "Invalid city.";
export const INVALID_RESIDENCY = "Invalid residency.";
export const INVALID_NATIONALITY = "Invalid nationality.";
export const INVALID_TITLE = "Invalid title.";
export const INVALID_BIRTH_DATE = "Invalid birth date.";
export const INVALID_MIDDLENAME = "Invalid middle name.";
export const INVALID_LASTNAME = "Invalid last name.";
export const INVALID_FIRSTNAME = "Invalid first name.";
export const INVALID_PHONE_NUMBER = "Invalid Phone Number.";
export const INVALID_PHONE_NUMBER_OR_TOKEN = "Invalid phone number or token.";
export const INVALID_PHONE_COUNTRY_CODE = "Invalid phone country code.";
export const FILE_TYPE_ERROR = "File type is not supported.";
export const FILE_EXTENSION_ERROR =
    "Allowed extensions are jpg, jpeg, png, pdf";
export const FILE_SIZE_ERROR = "File is too large. Maximum file size is 3MB.";
export const INVALID_SECRET_KEYS = "Invalid site/secret keys.";
export const INVALID_CONFIG_SETTINGS = "Invalid configuration settings.";
export const BALANCE_FAILED = "Failed to get balance.";
export const GET_ACCOUNT_CLOSURES = "Account Closure reasons retrieved.";
export const FAILED_GET_ACCOUNT_CLOSURES =
    "Failed to retrieve account closure reasons.";
export const FAILED_CHANGE_PASSWORD = "Failed to change password.";
export const SUCCESS_CHANGE_PASSWORD = "Your password has been changed.";
export const ERROR_OCCURED = "An unknown error occurred.";
export const FAILED_FAVOURITE_NUMBERS = "Failed to add new favourite numbers.";
export const UNRECOGNIZED_GAME_INFO = "Unrecognized game info.";
export const LOGOUT_FAILED = "Logout failed!";
export const LOGOUT_SUCCESS = "Logout successful.";
export const ACCOUNT_CHANGES_FAILED = "Failed to make account changes.";
export const ACCOUNT_CHANGES_SUCCESS = "Your account has been changed.";
export const INVALID_ACCOUNT_INFO = "Invalid Account Info or Token.";
export const INVALID_GAME_GROUP_ID = "Invalid Game Group Id.";

// multilinual messages
export const _UNEXPECTED_ERRORS = {
    EN: "An unexpected error occurred",
    AR: "حدث خطأ غير متوقع",
};

export const _INTERNAL_SERVER_ERROR = {
    EN: "Thank you for visiting our website. The system is currently undergoing maintenance. Please try again later.",
    AR: "شكرا لكم لزيارة موقعنا. النظام يخضع حاليا للصيانة. يرجى المحاولة مرة أخرى في وقت لاحق.",
};

export const _INVALID_REQUEST_ERROR = {
    EN: "Invalid request",
    AR: "طلب غير صالح",
};
