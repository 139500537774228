"use client";

import axios from "axios";
import { INTERNAL_SERVER_ERROR } from "@/utils/error-constants";
import { getBalanceFreeTickets } from "@/services/client/endpoint";

export default async function sendGetBalanceFreeTickets(csrfToken = "") {
  try {
    const response = await axios.post(
      getBalanceFreeTickets, // Endpoint URL
      {}, // No body payload
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken || "",
        },
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    if (error.response) {
      if (error.response.status >= 500) {
        throw new Error(INTERNAL_SERVER_ERROR);
      }
      return error.response.data; // Return specific error response data
    }
    return { message: error.message }; // Handle other errors (e.g., network issues)
  }
}
