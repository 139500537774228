"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  message: "",
  type:"",
};

export const showToasterSlice = createSlice({
  name: "toaster",
  initialState,
  reducers: {
    showToaster: (state, action) => {
      state.show = true;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    hideToaster: (state) => {
      state.show = false;
      state.message = "";
      state.type = ""
    },
  },
});

export default showToasterSlice.reducer;

export const { showToaster, hideToaster } = showToasterSlice.actions;
