// Commonly used numeric constants
export const DIGITS = {
    FLOAT_ZERO: 0.0,
    ZERO: 0,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    NINE: 9,
    TEN: 10,
    DIGIT_11: 11,
    DIGIT_12: 12,
    DIGIT_13: 13,
    DIGIT_14: 14,
    DIGIT_15: 15,
    DIGIT_16: 16,
    DIGIT_17: 17,
    DIGIT_18: 18,
    DIGIT_19: 19,
    DIGIT_20: 20,
    DIGIT_24: 24,
    DIGIT_28: 28,
    DIGIT_30: 30,
    DIGIT_45: 45,
    DIGIT_49: 49,
    DIGIT_50: 50,
    DIGIT_60: 60,
    DIGIT_83: 83,
    HUNDRED: 100,
    HUNDRED_TWO: 102,
    HUNDRED_NINE: 109,
    DIGIT_150: 150,
    DIGIT_200: 200,
    DIGIT_204: 204,
    DIGIT_225: 225,
    DIGIT_275: 275,
    DIGIT_300: 300,
    DIGIT_1000: 1000,
    DIGIT_320: 320,
    DIGIT_327: 327,
    DIGIT_339: 339,
    DIGIT_375: 375,
    DIGIT_400: 400,
    DIGIT_401: 401,
    DIGIT_403: 403,
    DIGIT_468: 468,
    DIGIT_500: 500,
    DIGIT_580: 580,
    DIGIT_768: 768,
    DIGIT_964: 964,
    DIGIT_971: 971,
    THOUSAND: 1000,
    DIGIT_1024: 1024,
    DIGIT_1800: 43200,
    DIGIT_1924: 1924,
    DIGIT_3200: 3200,
    DIGIT_4000: 4000,
    DIGIT_5000: 5000,
    DIGIT_8000: 8000,
    DIGIT_30000: 300000,
    DIGIT_45000: 45000,
    DIGIT_60000: 60000,
    MILLION: 1000000,
    THREE_LAKH: 300000,
    BILLION: 1000000000,
};

export const COLORS = {
    RED: "#dc3545",
    YELLOW: "#fee556",
    ORANGE: "#fb9c64",
    PINK: "#fb7a74",
    BROWN: "#444444",
    WHITE: "#fff",
    BLACK: "#000",
};

export const STRING_CONSTANTS = {
    UPCOMING: "upcoming",
    HEADING: "heading",
    ONE: "one",
    TWO: "two",
    GST: "GST",
    CREDIT_NOTE: "Credit Note",
    INVOICE: "Invoice",
    BACK: "Back",
    OTP_SMS: "Your IQM OTP is $$PIN$$",
    PC: "PC",
    SMART_PHONE: "Smartphone",
    MOBILE: "Mobile",
    TABLET: "Tablet",
    LINUX: "Linux",
    WINDOWS: "Windows",
    MACOS: "MacOS",
    MARKETING: "marketing",
    SMS: "SMS",
    EMAIL: "Email",
    WHATSAPP: "Whatsapp",
    PASSWORD: "password",
    MOBILE_NUMBER: "MobileNumber",
    BIRTH_DATE: "DateOfBirth",
    LOCALHOST_IP: "127.0.0.1",
    ACCOUNT_CREATED: "account_created",
    COUNTRY_PREFIX_1: "1-",
    COUNTRY_PREFIX_O1: "O1",
    UNKNOWN_GENDER: "U",
    DEFAULT_OTP_CODE: "000000",
    ENTITY_ID: "entityID",
    TOKEN: "token",
    NONE: "none",
    NODATA: "No data available",
    WITHDRAWAL: "Withdrawal",
    PRECARD: "PrepaidCard",
    CHARGE: "Charges",
    CREDIT: "Credit",
    FEE: "Fee",
    FAILURE: "Failure",
    DAILY: "Daily",
    TIMEOUT: "TimeOut",
    GET_RESTRICTIONS_SUCCESS: "Restrictions retrieved.",
    GET_RESTRICTIONS_FAILED: "Failed to retrieve restrictions.",
    REJECT: "reject",
    AE: "AE",
    EN: "EN",
    PASSPORT: "passport",
    ID: "id",
    DOCUMENT_BACK: "documentBack",
    REJECTED: "Rejected",
    KYC_UNVERIFIED: "KYC_Unverified",
    VERIFIED: "Verified",
    HIGH: "high",
    RENEW: "Renew",
    USER_NOT_FOUND: "No auth user found.",
};
export const STRING_CONSTANTS_AR = {
    GST: "ت.خ.ق.",
    CREDIT_NOTE: "إشعار استرداد رصيد",
    INVOICE: "فاتورة",
    NODATA: "لا توجد بيانات متاحة",
    WITHDRAWAL: "سحب",
    CREDIT: "ائتمان",
    FEE: "رسوم",
};

export const LINKS = {
    LOGIN: "/login",
    ADD_CREDIT_LINK: "/my-account/add-credit",
    WITHDRAW: "/my-account/withdraw",
    BALANCE_SUMMARY: "/my-account/balance-summary",
    MY_PURCHASE: "/my-account/history",
    MY_PROFILE: "/my-account/my-profile",
};
export const KEY = {
    PAY_METH: "paymentMethod.Name",
    PAY_METH_TYPE: "paymentMethodType.Name",
    ORDER_ID: "paymentOrder.GatewayOrderReference",
    ACC_REF: "playerPaymentAccount.AccountReference",
    TCK: "ticket.TicketID",
    DSP: "gameVertical.DisplayName",
    RAFFLE: "Raffle draw Winning",
    DRAW: "draw.DrawDate",
    MATCH: "matchingNumbers",
    GRAND: "Grand draw Winning",
    COUPON: "coupon.CouponID",
    PLAYER_REGISTER: "player.register",
    PLAYER_BAL_TYPE: "playerBalanceType.DisplayName",
    CAPTCHA_TOKEN: "captcha_token",
    HOME: "home",
    PAY_FAILURE: "paymentState.DisplayName",
    PROFILE_UPDATE: "profile_update",
    TIMEOUT: "time-out",
    SELF_EXCLUSION: "self-exclusion",
    CLOSE_ACCOUNT: "close-account",
    GENDER_MISMATCHED: "GENDER_MISMATCHED",
    DOCUMENT_EXPIRED: "DOCUMENT_EXPIRED",
    UNKNOWN: "UNKNOWN",
    TRANSACTION_ID: "transactionId",
    LENGTH_REQUIRED: "length-req",
    LETTER_REQUIRED: "letter-req",
    DIGIT_REQUIRED: "digit-req",
    CHAR_REQUIRED: "char-req",
    REFRESH_TOKEN: "refresh_token",
    AUTH_ACCESS_TOKEN: "auth_access_token",
    ACCOUNT_CLOSED: "account_closed",
    GLOBAL_ACCESS_TOKEN: "global_access_token",
};
