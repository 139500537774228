"use client";
import PropTypes from "prop-types";
import { useEffect } from "react";
import Loader from "@/components/loader";
import Header from "@/components/templates/header";
import Footer from "@/components/templates/footer";
import ManageAccountConfirmations from "@/components/my-account/manage-account/confirmations";
import ShowFavouritesModal from "@/components/my-account/favourite-numbers/saved-favourites/modal";
import AddFavouritesModal from "@/components/my-account/favourite-numbers/add-favourite/modal";
import NonPassportUpload from "@/components/my-account/verification/non-passport-upload";
import { usePathname } from "next/navigation";
import {
  useSelectShowMobileMenu,
  useDispatchSetShowMobileMenu,
  useDispatchUnsetAllActiveLineCards,
  useSelectAuthUser,
  useSelectGameGroups,
} from "@/hooks";
import FrameProtection from "@/components/frame-protection";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// Styles
import "react-datepicker/dist/react-datepicker.css";
import "@/app/[lang]/styles/jira344vendors.min.css";
import "@/app/[lang]/styles/jira449app.min.css";
import Toaster from "@/components/common/toaster";
import Script from "next/script";
import { trackUIEvent } from "@/utils/gtm";
import { useDispatch } from "react-redux";
import { setGTMVal } from "@/redux/slices/authUserSlice";
import { getPageGTMData } from "@/utils/gtmConstant";
import HandleScripts from "./handleScripts";
import { generateSHA256 } from "@/utils/functions.client";

export default function App({
  children,
  lang,
  headerLogo,
  quick_links,
  quick_links_auths,
  menus,
  locales,
  footerMenus,
  mobileMenus,
  socialLinks,
  contactInfo,
  footerLogo,
  copyright,
  cart,
  greetingText,
  languageCurrencyTitle,
  csrfToken,
  loginUrl,
  dir,
  my_account_menus,
  captchaSiteKey = "",
}) {
  const showMobileMenu = useSelectShowMobileMenu();
  const dispatchSetShowMobileMenu = useDispatchSetShowMobileMenu();
  const dispatchUnsetAllCards = useDispatchUnsetAllActiveLineCards();
  const pathName = usePathname();
  const authUser = useSelectAuthUser();
  const dispatch = useDispatch();
  const gamegroups = useSelectGameGroups();
  const currency = gamegroups?.[0]?.gamePrice?.currencyCode;
  const gameGroupID = gamegroups?.[0]?.gameGroupID;

  const headerData = {
    headerLogo,
    quick_links,
    quick_links_auths,
    menus,
    locales,
    cart,
    greetingText,
    languageCurrencyTitle,
    my_account_menus,
  };
  const footerData = {
    footerMenus,
    mobileMenus,
    socialLinks,
    contactInfo,
    footerLogo,
    copyright,
  };
  lang = lang.toLowerCase();

  const handleToggleMobileMenu = (e) => {
    if (showMobileMenu) {
      if (!e.target.closest(".header__nav")) {
        dispatchSetShowMobileMenu(false);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (parseInt(window.innerWidth) > 991) {
        dispatchSetShowMobileMenu(false);
      }
    };

    window.addEventListener("resize", handleResize);
    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatchSetShowMobileMenu]);

  function isTicketDetailsPath(patterns, path) {
    return patterns.some((pattern) => {
      const regexPattern = pattern.replace(/\[id\]/g, "([^/]+)");
      const regex = new RegExp(`^${regexPattern}$`);
      return regex.test(path);
    });
  }

  const patterns = ["/" + lang + "/my-account/history/[id]"];

  const isTicketDetails = isTicketDetailsPath(patterns, pathName);

  function storePathValues() {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    const prevPath = storage.getItem("currentPath");
    storage.setItem("prevPath", prevPath);
    storage.setItem("currentPath", globalThis.location.pathname);
  }

  //unset all cards
  useEffect(() => {
    if (typeof window !== "undefined") {
      storePathValues();
      const trimURL = sessionStorage.getItem("prevPath");
      const prevPageURL = trimURL?.split("/").filter(Boolean).pop();
      const lastSegment = pathName?.split("/").filter(Boolean).pop();
      const { category, subcategory, pagename } = getPageGTMData(lastSegment);
      const userInfo = {
        lang,
        pagename,
        category,
        subcategory,
        prevURL:
          prevPageURL === "en" || prevPageURL === "ar" ? "Home" : prevPageURL,
        dateOfBirth: authUser?.profile?.dateOfBirth,
        playerID: authUser?.profile?.playerID,
      };
      trackUIEvent(
        "e_pageview",
        userInfo.lang,
        userInfo.pagename,
        userInfo.category,
        userInfo.subcategory,
        userInfo.prevURL,
        userInfo.dateOfBirth,
        userInfo.playerID
      );
      const userHashedEmail = authUser?.profile?.email
        ? generateSHA256(authUser?.profile?.email)
        : "";
      const userHashedMobile = authUser?.profile?.mobileNumbers[0]?.mobileNumber
        ? generateSHA256(authUser?.profile?.mobileNumbers[0]?.mobileNumber)
        : "";

      dispatch(
        setGTMVal({
          lang: userInfo.lang,
          currentPage: userInfo.pagename,
          category: userInfo.category,
          subcategory: userInfo.subcategory,
          previousPage: userInfo.prevURL,
          loggedIn: userInfo.dateOfBirth,
          userid: userInfo.playerID,
          currency: "USD",
          gameID: gameGroupID,
          hashedEmail: userHashedEmail || null,
          hashedPhone: userHashedMobile || null,
        })
      );

      snaptr("track", "PAGE_VIEW", {
        user_hashed_email: userHashedEmail || "",
        user_hashed_phone_number: userHashedMobile || "",
        item_ids: gameGroupID?.toString(),
        item_category: "",
        uuid_c1: authUser?.profile?.playerID,
      });
    }
    if (pathName !== "/en/participate" && pathName !== "/ar/participate") {
      dispatchUnsetAllCards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName, authUser]);

  return (
    <>
      {process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID ? (
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
          strategy="afterInteractive"
        />
      ) : null}
      {process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID ? (
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}');
        `}
        </Script>
      ) : null}
      <HandleScripts />
      <body
        className={
          (lang === "ar" || lang === "kd" ? "page-ar page-rtl" : "page-en") +
          " logged_out skin_449 windows " +
          (showMobileMenu ? "menu_lock" : "")
        }
        onClick={handleToggleMobileMenu}
      >
        {/* Google Tag Manager (noscript) */}
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
        {/* End Google Tag Manager (noscript) */}
        <noscript>
          <link
            rel="preload"
            href="https://www.facebook.com/tr?id=1969879423485897&ev=PageView&noscript=1"
            as="image"
          />
        </noscript>
        <FrameProtection />
        <div className="wrapper" dir={dir}>
          <Toaster />
          {!isTicketDetails ? <Loader /> : ""}
          {!isTicketDetails && pathName != "/en/debug" ? (
            <Header
              {...headerData}
              csrfToken={csrfToken || ""}
              loginUrl={loginUrl || ""}
            />
          ) : (
            ""
          )}

          <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
            <div className="wrap" role="document">
              <div className="main" role="main" style={{ minHeight: "58.4vh" }}>
                {children}
              </div>
              {!isTicketDetails && pathName != "/en/debug" ? (
                <Footer {...footerData} />
              ) : (
                ""
              )}
            </div>
          </GoogleReCaptchaProvider>
        </div>
        <ManageAccountConfirmations />
        <NonPassportUpload />
        <ShowFavouritesModal />
        <AddFavouritesModal />
      </body>
    </>
  );
}

App.propTypes = {
  /**
   * Node children
   */
  children: PropTypes.node.isRequired,
  /**
   * Current language
   */
  lang: PropTypes.string.isRequired,
  /**
   * Header Logo
   */
  headerLogo: PropTypes.string.isRequired,
  /**
   * Cart info
   */
  cart: PropTypes.object.isRequired,
  /**
   * Link on the header right side
   */
  quick_links: PropTypes.array.isRequired,
  /**
   * Link on the header right side for authenticated user
   */
  quick_links_auths: PropTypes.array.isRequired,
  /**
   * Navigation menu items
   */
  menus: PropTypes.array.isRequired,
  /**
   * Languages
   */
  locales: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Country code
       */
      code: PropTypes.string.isRequired,
      /**
       * Language name
       */
      name: PropTypes.string.isRequired,
      /**
       * Image
       */
      image: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * Navigation menu items
   */
  footerMenus: PropTypes.array.isRequired,
  /**
   * Navigation menu items for mobile
   */
  mobileMenus: PropTypes.array.isRequired,
  /**
   * Footer social links
   */
  socialLinks: PropTypes.object.isRequired,
  /**
   * Footer Contact info
   */
  contactInfo: PropTypes.object.isRequired,
  /**
   * Footer Logo
   */
  footerLogo: PropTypes.string.isRequired,
  /**
   * Copyright
   */
  copyright: PropTypes.string.isRequired,
  /**
   * Greeting text
   */
  greetingText: PropTypes.string.isRequired,
  /**
   * Language and Currency title
   */
  languageCurrencyTitle: PropTypes.string.isRequired,
  /**
   * CSRF Token
   */
  csrfToken: PropTypes.string,
  /**
   * Login URL
   */
  loginUrl: PropTypes.string.isRequired,
};
