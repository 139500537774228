"use client";
import PropTypes from "prop-types";
import { ListLink } from "@/utils/components.client";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import sendGetBalanceFreeTickets from "@/services/client/sendGetBalanceFreeTickets";
import BalanceSummary from "./balance-summary";
import {
  useDispatchShowLoader,
  useRedirect,
  useSelectCsrfToken,
  useDispatchLogout,
  useGetPathName,
  useSelectLocale,
  useDispatchSetPurchaseCartId,
} from "@/hooks";
import { trackBuynowEvent, trackClickEvent } from "@/utils/gtm";
import { useSelector } from "react-redux";
import { useDispatchSetFreeTickets } from "@/hooks";
import { useState, useEffect } from "react";

export default function LoggedInMenu({
  username = "User",
  quick_links_auths = [],
  greetingText = "Hi",
  loginUrl = "",
}) {
  const csrfToken = useSelectCsrfToken();
  const [setFreeTicket, updateFreeTicket] = useState([]);
  const isBrowser = typeof window !== "undefined";
  const gameGroupID = isBrowser ? sessionStorage.getItem("gameGroupID") : null;
  const dispatchShowLoader = useDispatchShowLoader();
  const dispatchLogout = useDispatchLogout();
  const redirect = useRedirect();
  const locale = useSelectLocale();
  let pathName = useGetPathName();
  const gtmData = useSelector((state) => state?.authUser?.data);
  const dispatchSetFreeTickts = useDispatchSetFreeTickets();
  const dispatchSetCheckoutCartID = useDispatchSetPurchaseCartId();

  if (pathName?.toLowerCase() === "/" + locale + "/create-account")
    pathName = "/" + locale + "/login";

  const gtmbuynowEvent = () => {
    trackBuynowEvent(
      "e_headerinteraction",
      "non_ecommerce",
      "buy_now",
      "millionaire",
      gtmData?.lang || "en",
      gtmData?.currentPage || "",
      gtmData?.category || "",
      gtmData?.subcategory || "",
      gtmData?.previousPage || "",
      gtmData?.loggedIn || "",
      gtmData?.userid || ""
    );
  };
  const gtmEvent = () => {
    trackClickEvent(
      "e_headerinteraction",
      "non_ecommerce",
      "top_up",
      "",
      gtmData?.lang || "en",
      gtmData?.currentPage || "",
      gtmData?.category || "",
      gtmData?.subcategory || "",
      gtmData?.previousPage || "",
      gtmData?.loggedIn || "",
      gtmData?.userid || ""
    );
  };
  const callFreeTicketsApi = () => {
    async function getFreeTickets() {
      try {
        const response = await sendGetBalanceFreeTickets(csrfToken);
        let data = response?.freeTicketBalances?.freeTicketBalances;
       
        const parsedGameGroupID = Number(gameGroupID);
        if (data?.length) {
          const filteredDataByGameGroupId = data?.filter((item) => {
            return item?.gameGroupID === parsedGameGroupID; // Return the condition
          });
         
          updateFreeTicket(filteredDataByGameGroupId);
        }else{
          updateFreeTicket([]);
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (csrfToken) getFreeTickets();
  };


  return (
    <div className="header__login-menu logged">
      <ul>
        <ListLink
          text={quick_links_auths?.[0]?.attributes?.name || ""}
          listClassName="highlighted"
          link={quick_links_auths?.[0]?.attributes?.url || "#link-here"}
          linkId="buy_now_header_desktop"
          onClickExtension={(e) => {
            gtmbuynowEvent();
            if (!!quick_links_auths?.[0]?.attributes?.isDisabled) {
              e.preventDefault();
              return;
            }
          }}
        />
        <ListLink
          text={quick_links_auths?.[1]?.attributes?.name || ""}
          listClassName="highlighted"
          linkId="buy_now_header_desktop"
          link={quick_links_auths?.[1]?.attributes?.url || "#link-here"}
          onClickExtension={(e) => {
            gtmEvent();
            dispatchSetCheckoutCartID("");
            if (!!quick_links_auths?.[1]?.attributes?.isDisabled) {
              e.preventDefault();
              return;
            }
          }}
        />
        <li>
          <a
            href="#"
            title={quick_links_auths?.[2]?.attributes?.name || ""}
            className="bold-iq"
            onMouseEnter={callFreeTicketsApi}
            onClick={(e) => {
              e.preventDefault(); // Prevent the default action of the <a> tag
              callFreeTicketsApi(); // Trigger the API call
            }}
          >
            {quick_links_auths?.[2]?.attributes?.name || ""}
          </a>
          <BalanceSummary
            content={quick_links_auths?.[2] || {}}
            csrfToken={csrfToken || ""}
            setFreeTicket={setFreeTicket}
          />
        </li>
        <li>
          <p className="header__login-menu--welcome fs14 reqular-iq">
            {greetingText || "Hi"}, {username || "User"}
          </p>
          <a
            href=""
            title="My Account"
            className="bold-iq"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {quick_links_auths?.[3]?.attributes?.name || ""}
            <i className="far fa-chevron-down"></i>
          </a>
          <div className="header__dropdown primary">
            <ul>
              <ListLink
                text={
                  quick_links_auths?.[3]?.attributes?.subItems?.[2]?.text || ""
                }
                link={
                  quick_links_auths?.[3]?.attributes?.subItems?.[2]?.link ||
                  "#my-account"
                }
              />
              <ListLink
                text={
                  quick_links_auths?.[3]?.attributes?.subItems?.[3]?.text || ""
                }
                link={
                  quick_links_auths?.[3]?.attributes?.subItems?.[3]?.link ||
                  "#my-account"
                }
              />
              <ListLink
                text={
                  quick_links_auths?.[3]?.attributes?.subItems?.[4]?.text || ""
                }
                link={
                  quick_links_auths?.[3]?.attributes?.subItems?.[4]?.link ||
                  "#my-account"
                }
              />
              <ListLink
                text={
                  quick_links_auths?.[3]?.attributes?.subItems?.[5]?.text || ""
                }
                link={
                  quick_links_auths?.[3]?.attributes?.subItems?.[5]?.link ||
                  "#my-account"
                }
              />

              <ListLink
                text={
                  quick_links_auths?.[3]?.attributes?.subItems?.[1]?.text || ""
                }
                link={
                  quick_links_auths?.[3]?.attributes?.subItems?.[1]?.link ||
                  "#log-out"
                }
                onClickExtension={async (e) => {
                  e.preventDefault();
                  // sessionStorage.removeItem("gameGroupID");
                  dispatchShowLoader(true);
                  dispatchLogout();
                  const logout = await sendInternalPostRequest({
                    endpoint: "/api/logout",
                    csrfToken,
                  });

                  if (logout?.success) {
                    dispatchSetFreeTickts([]);
                    redirect("/" + locale + "/login");
                    // redirect(pathName);
                  }
                  dispatchShowLoader(false);
                  trackClickEvent(
                    "e_headerinteraction",
                    "non_ecommerce",
                    "logout",
                    "",
                    gtmData?.lang || "en",
                    gtmData?.currentPage || "",
                    gtmData?.category || "",
                    gtmData?.subcategory || "",
                    gtmData?.previousPage || "",
                    gtmData?.loggedIn || "",
                    gtmData?.userid || ""
                  );
                }}
              />
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
}
LoggedInMenu.propTypes = {
  /**
   * Username
   */
  username: PropTypes.string,
  /**
   * Links for auth users
   */
  quick_links_auths: PropTypes.array.isRequired,
  /**
   * Greeting text
   */
  greetingText: PropTypes.string.isRequired,
};
